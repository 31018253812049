
import Vue from "vue"
import { AccountItem, GetAccountDetails} from "@/types/responses"
//import SubHeader from "../global/SubHeader.vue"
export default Vue.extend({
	name: "AccountDetailsTable",
	props: {
/* 	loading: {
			type: Boolean,
            required: true
		}, */
        showRoomList: {
            type: Boolean,
            required: true
        },
        showRemoveWarnings:{
            type: Boolean,
            default: false
        }
		//itemDetails: Object as () => GetAccountDetails,
		//item: Object as () => AccountItem,
	},
    mounted() {
		this.getAccountSummary()
	},
    data(): {
		item: null | AccountItem
        loading: boolean
        requestingAccountDetailsFor: string
		
	} {
		return {
			item: null,
            loading: false,
            requestingAccountDetailsFor: ""
			}
	},

	computed: {

		isDetailsMatching(): boolean{
            const itemDetails = this.getAccountDetails
            const item = this.getSelectedAccount
            //const item = 
			if (itemDetails !== null && itemDetails.userId === item.userId){
				return true
			}else{
				return false
			}
		},
        getSelectedAccount(): AccountItem {
            return this.$store.state.context.selectedAccount
        },
		getAccountDetails(): GetAccountDetails{
			return this.$store.state.context.accountSummary
		}
        
	},
    methods: {
		async getAccountSummary(): Promise<void> {

            const userId = this.getSelectedAccount.userId
			//this.removing = true
			if (this.loading && userId === this.requestingAccountDetailsFor) return
			
			this.loading = true
			
			this.requestingAccountDetailsFor = userId

			try {
				console.log("Running account details on: "+ userId)
				await this.$vStore.dispatch("context/accountSummary", userId)
			} catch (e) {
				console.warn("Could not find account details for this account.", e)
			}
			this.loading = false
		}        
    },

})
